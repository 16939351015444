//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2023 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import { DisplaySizeExtractor } from './DisplaySizeExtractor';

/*
* @param {THREE.PerspectiveCamera}
* @param {THREE.WebVRManager}
* @param {{width:number, height:number}}
*/
var ViewportController = function(camera, webXRManager,
  developmentDisplaySize = {width:0.344, height:0.193}){

  if(camera === null || webXRManager === null){
    console.error(
      'ViewportController requires at least a THREE.Camera and the running' +
      'WebXRManager at construction');
    return null;
  }

  THREE.Object3D.call(this);

  //////////////////////////////////////////////////////////////////////////////
  // Private Memebers //////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  this._camera = camera;

  this.add(this._camera);

  this._camera.position.set(0,0,0);

  this._webXRManager = null;

  this.webXRManager = webXRManager;

  this._lookDirection = new THREE.Vector3(0,0,1);
  this._lookUpVector = new THREE.Vector3(0,1,0);
  this._viewerScale = 1;

  this._depthNear = 0.01
  this._depthFar = 100000;

  this._developmentDisplaySize = developmentDisplaySize;

  // just assuming that they're the same until we know otherwise
  this._runtimeDisplaySize = developmentDisplaySize;

  this._displaySizeExtractor = null;

  this._pixelWidth  = 1920;
  this._pixelHeight = 1080;

  if(this._webXRManager.isPresenting()){
    this.onSessionStart();
  }else{
    this._webXRManager.addEventListener('sessionstart',
      ()=>{this.onSessionStart();});
  }
};

////////////////////////////////////////////////////////////////////////////////
// Methods /////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

ViewportController.prototype =
  Object.assign(Object.create(THREE.Object3D.prototype), {

  constructor: ViewportController,

  onSessionStart: function(evt){
    this._session = this._webXRManager.getSession();
    this._vrCam = this._webXRManager.getCamera(this._camera);
    this.viewerScale = this._viewerScale;

    if(this._displaySizeExtractor == null){
      this._displaySizeExtractor = new DisplaySizeExtractor(this._session);
    }
    else
    {
      this._displaySizeExtractor._session = this._session;
    }

    this._displaySizeExtractor.QueryDisplaySize().then((size)=>{
      this._runtimeDisplaySize = size;
    });
  },

  setLookDirection: function(direction){
    this._lookDirection = direction;
    this.rotation.setFromRotationMatrix(new THREE.Matrix4().lookAt(
      this._lookDirection,
      new THREE.Vector3(0,0,0),
      this._lookUpVector));
  },

  setLookUp: function(up){
    this._lookUpVector = up;
    this.setLookDirection(this._lookDirection);
  },

  update: function(){
    if(this._webXRManager.isPresenting()){
      this._camera.position.set(0,0,0);
    }else if(!this._webXRManager.isPresenting()){
      // TODO: use a more accurate fallback camera pose that
      // aligns to the viewport edges.
      this._camera.position.set(0, 0, 0.32);
    }
  }
});

////////////////////////////////////////////////////////////////////////////////
// Accessors ///////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

ViewportController.prototype = Object.create(ViewportController.prototype, {
  webXRManager:{
    get: function(){return this._webXRManager;},

    set: function(value){
      this._webXRManager = value;

      if(this._webXRManager.isPresenting()){
        this.onSessionStart();
      }else{
        this._webXRManager.addEventListener('sessionstart',
          ()=>{this.onSessionStart();});
      }
    }
  },

  session:{
    get: function(){
      return this._session;
    }
  },

  depthNear:{
    get: function(){
      return this._depthNear;
    },

    set: function(value){
      if(value <= 0){
        console.error('near plane clipping cannot be less than or equal to zero.');
        return;
      }

      this._depthNear = value;
      this._camera.near = value;

      if(this.webXRManager.isPresenting()){
        this._session.updateRenderState(
          {depthNear: this._depthNear, depthFar: this._depthFar});
      }else{
        this._camera.updateProjectionMatrix();
      }
    }
  },

  depthFar:{
    get: function(){
      return this._depthFar;
    },
    set: function(value){
      if(value <= 0){
        console.error('far plane clipping cannot be less than or equal to zero.');
        return;
      }

      this._depthFar = value;
      this._camera.far = value;

      if(this.webXRManager.isPresenting()){
        this._session.updateRenderState(
          {depthNear: this._depthNear, depthFar: this._depthFar});
      }else{
        this._camera.updateProjectionMatrix();
      }
    }
  },

  viewerScale:{
    get: function(){
      return this._viewerScale;
    },
    set: function(value){
      if(value <= 0){
        console.error('Viewer Scale cannot be less than or equal to zero.');
        return;
      }
      this._viewerScale = value;
      this.scale.setScalar(this.runtimeViewerScale);
      if(this.webXRManager.isPresenting()){
        //TODO: make clipping proportional to viewer scale
        this._session.updateRenderState({depthNear: this._depthNear, depthFar:this._depthFar});
      }
    }
  },

  runtimeViewerScale:{
    get: function(){
      return this.viewerScale *
        (this._developmentDisplaySize.width +
        this._developmentDisplaySize.height) /
        (this.width + this.height);
    }
  },

  width:{
    get: function(){
      return this._runtimeDisplaySize.width;
    }
  },

  height:{
    get: function(){
      return this._runtimeDisplaySize.height;
    }
  },

  metersPerPixelWidth:{
    get: function(){
      return this.width/this._pixelWidth;
    }
  },

  metersPerPixelHeight:{
    get: function(){
      return this.height/this._pixelHeight;
    }
  }
});

export { ViewportController };
