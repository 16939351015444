//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2023 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

var DisplaySizeExtractor = function(xrSession) {
  this._planes = [];

  this._displaySize = {x:0, y:0};

  this._session = xrSession;
  this._referenceSpace = null;

  this._headPos = [0,0,0,1];

  this._raycastLeft   = null;
  this._raycastRight  = null;
  this._raycastTop    = null;
  this._raycastBottom = null;

  this.resolve = null;
  this.reject = null;
};

DisplaySizeExtractor.prototype = {

  QueryDisplaySize(){
    return new Promise((resolve, reject)=>{

      this.resolve = resolve;
      this.reject = reject;

      if(this._referenceSpace === null){
        this._session.requestReferenceSpace('local').then(
          (refSpace)=>{
            this.referenceSpace = refSpace;
            this._session.requestAnimationFrame(this._onXrFrame.bind(this));
          }
        ).catch((err)=>{this.reject(err);});
      }else{
        this._session.requestAnimationFrame(this._onXrFrame.bind(this));
      }
    });
  },

  _onXrFrame(time, frame) {
    // left eye
    const view = frame.getViewerPose(this.referenceSpace).views[0];

    const projection = this._mat4Transpose(view.projectionMatrix);
    const viewInverse = this._mat4Transpose(view.transform.inverse.matrix);

    const combo = this._mat4multiply(projection, viewInverse);

    this._planes = this._extractPlanes(combo);

    this._planes = this._normalizeFrustumPlanes(this._planes);

    this._headPos = view.transform.inverse.position;

    this._headPos = [
      view.transform.inverse.position.x,
      view.transform.inverse.position.y,
      view.transform.inverse.position.z,
      view.transform.inverse.position.w
    ];

    this._raycastLeft = this._intersectRayPlaneDistance(
      this._headPos, this._planes[0], [0,0,0], [1,0,0])

    this._raycastRight = this._intersectRayPlaneDistance(
      this._headPos, this._planes[1], [0,0,0], [-1,0,0])

    this._raycastTop = this._intersectRayPlaneDistance(
      this._headPos, this._planes[2], [0,0,0], [0,1,0])

    this._raycastBottom = this._intersectRayPlaneDistance(
      this._headPos, this._planes[3], [0,0,0], [0,-1,0])

    this._displaySize = {
      width:  this._raycastLeft + this._raycastRight,
      height: this._raycastTop + this._raycastBottom
    };

    this.resolve(this._displaySize);
  },

  _intersectRayPlaneDistance: function(
    plane_pos, plane_norm, ray_pos, ray_norm) {

    if(plane_pos.length === 4) {
      plane_pos = this._vec4tovec3(plane_pos);
    }

    if(plane_norm.length === 4){
      plane_norm = this._vec4tovec3(plane_norm);
    }

    return this._vec3dot(plane_norm, this._vec3subtract(ray_pos, plane_pos)) /
      this._vec3dot(plane_norm, this._vec3subtract([0,0,0], ray_norm));
  },

//  intersectRayPlanePoint: function(plane_pos, plane_norm, ray_pos, ray_norm) {
//    let distance = this.vec3dot(plane_norm,
//      this.vec3subtract(ray_pos, plane_pos)) /
//        this.vec3dot(plane_norm,
//      this.vec3subtract([0,0,0], ray_norm));
//
//    return point + distance * direction;
//  },

//  _vec3add: function(a, b) {
//    let out = [];
//    out[0] = a[0] + b[0];
//    out[1] = a[1] + b[1];
//    out[2] = a[2] + b[2];
//    return out;
//  },

  _vec3subtract: function(a, b) {
    let out = [];
    out[0] = a[0] - b[0];
    out[1] = a[1] - b[1];
    out[2] = a[2] - b[2];
    return out;
  },

  _vec3multiply: function(a, b) {
    let out = [];
    out[0] = a[0] * b[0];
    out[1] = a[1] * b[1];
    out[2] = a[2] * b[2];
    return out;
  },

  _vec3dot: function(a, b) {
    let x = a[0] * b[0];
    let y = a[1] * b[1];
    let z = a[2] * b[2];
    let add = x + y;
    add = add + z;
    return add;
  },

  _mat4Transpose: function(a) {
    let out = [];
    out[0] = a[0];
    out[1] = a[4];
    out[2] = a[8];
    out[3] = a[12];
    out[4] = a[1];
    out[5] = a[5];
    out[6] = a[9];
    out[7] = a[13];
    out[8] = a[2];
    out[9] = a[6];
    out[10] = a[10];
    out[11] = a[14];
    out[12] = a[3];
    out[13] = a[7];
    out[14] = a[11];
    out[15] = a[15];
    return out;
  },

  _extractPlanes: function(M, zNear, zFar) {
//    var z  = zNear || 0.0;
//    var zf = zFar || 1.0;
    return [
      // Left
      [ M[12] + M[0], M[13] + M[1], M[14] + M[2], M[15] + M[3] ],

      // Right
      [ M[12] - M[0], M[13] - M[1], M[14] - M[2], M[15] - M[3] ],

      // Top
      [ M[12] + M[4], M[13] + M[5], M[14] + M[6], M[15] + M[7] ],

      // Bottom
      [ M[12] - M[4], M[13] - M[5], M[14] - M[6], M[15] - M[7] ]

//      These aren't necessary for measuring height/width
//      // Back
//      [ z*M[12] + M[8], z*M[13] + M[9], z*M[14] + M[10], z*M[15] + M[11] ],
//      // Front
//      [ zf*M[12] - M[8], zf*M[13] - M[9], zf*M[14] - M[10], zf*M[15] - M[11] ]
    ]
  }, 

  _normalizePlane: function(plane) {
    let normalizedPlane = [];
    let mag = Math.sqrt(
      plane[0] * plane[0] + plane[1] * plane[1] + plane[2] * plane[2]);
    normalizedPlane.push(plane[0] / mag);
    normalizedPlane.push(plane[1] / mag);
    normalizedPlane.push(plane[2] / mag);
    normalizedPlane.push(/*plane[3]*/ 1 / mag);
    return normalizedPlane;
  },

  _normalizeFrustumPlanes: function(planes) {
    let normalizedPlanes = []
    for(let i = 0; i < planes.length; i++){
      normalizedPlanes.push(this._normalizePlane(planes[i]));
    }
    return normalizedPlanes;
  },

//  vec4normalize(a) {
//    let x = a[0];
//    let y = a[1];
//    let z = a[2];
//    let w = a[3];
//    let len = x * x + y * y + z * z + w * w;
//    if (len > 0) {
//      len = 1 / Math.sqrt(len);
//    }
//
//    let out = [];
//    out[0] = x * len;
//    out[1] = y * len;
//    out[2] = z * len;
//    out[3] = w * len;
//    return out;
//  },

  _vec4tovec3: function(v) {
    return [v[0]*(1/v[3]), v[1]*(1/v[3]), v[2]*(1/v[3])];
  },

  _vec4TransformMat4: function(a, m) {
    let out = [0,0,0,1];
    let x = a[0],
      y = a[1],
      z = a[2],
      w = a[3];
    out[0] = m[0] * x + m[4] * y + m[8] * z + m[12] * w;
    out[1] = m[1] * x + m[5] * y + m[9] * z + m[13] * w;
    out[2] = m[2] * x + m[6] * y + m[10] * z + m[14] * w;
    out[3] = m[3] * x + m[7] * y + m[11] * z + m[15] * w;
    return out;
  },

  _vec3TransformMat4: function(a, m) {
    let out = [0,0,0];
    let x = a[0],
      y = a[1],
      z = a[2];
    let w = m[3] * x + m[7] * y + m[11] * z + m[15];
    w = w || 1.0;
    out[0] = (m[0] * x + m[4] * y + m[8] * z + m[12]) / w;
    out[1] = (m[1] * x + m[5] * y + m[9] * z + m[13]) / w;
    out[2] = (m[2] * x + m[6] * y + m[10] * z + m[14]) / w;
    return out;
  },

  _mat4multiply: function(a, b) {
    let out = [];

    let a00 = a[0],
      a01 = a[1],
      a02 = a[2],
      a03 = a[3];
    let a10 = a[4],
      a11 = a[5],
      a12 = a[6],
      a13 = a[7];
    let a20 = a[8],
      a21 = a[9],
      a22 = a[10],
      a23 = a[11];
    let a30 = a[12],
      a31 = a[13],
      a32 = a[14],
      a33 = a[15];

    // Cache only the current line of the second matrix
    let b0 = b[0],
      b1 = b[1],
      b2 = b[2],
      b3 = b[3];
    out[0] = b0 * a00 + b1 * a10 + b2 * a20 + b3 * a30;
    out[1] = b0 * a01 + b1 * a11 + b2 * a21 + b3 * a31;
    out[2] = b0 * a02 + b1 * a12 + b2 * a22 + b3 * a32;
    out[3] = b0 * a03 + b1 * a13 + b2 * a23 + b3 * a33;

    b0 = b[4];
    b1 = b[5];
    b2 = b[6];
    b3 = b[7];
    out[4] = b0 * a00 + b1 * a10 + b2 * a20 + b3 * a30;
    out[5] = b0 * a01 + b1 * a11 + b2 * a21 + b3 * a31;
    out[6] = b0 * a02 + b1 * a12 + b2 * a22 + b3 * a32;
    out[7] = b0 * a03 + b1 * a13 + b2 * a23 + b3 * a33;

    b0 = b[8];
    b1 = b[9];
    b2 = b[10];
    b3 = b[11];
    out[8] = b0 * a00 + b1 * a10 + b2 * a20 + b3 * a30;
    out[9] = b0 * a01 + b1 * a11 + b2 * a21 + b3 * a31;
    out[10] = b0 * a02 + b1 * a12 + b2 * a22 + b3 * a32;
    out[11] = b0 * a03 + b1 * a13 + b2 * a23 + b3 * a33;

    b0 = b[12];
    b1 = b[13];
    b2 = b[14];
    b3 = b[15];
    out[12] = b0 * a00 + b1 * a10 + b2 * a20 + b3 * a30;
    out[13] = b0 * a01 + b1 * a11 + b2 * a21 + b3 * a31;
    out[14] = b0 * a02 + b1 * a12 + b2 * a22 + b3 * a32;
    out[15] = b0 * a03 + b1 * a13 + b2 * a23 + b3 * a33;
    return out;
  },

//  //////////////////////////////////////////////////////////////////////////////
//  // Debug Printout Formatting /////////////////////////////////////////////////
//  //////////////////////////////////////////////////////////////////////////////
//
//  _planeToString: function(plane) {
//    return plane[0].toFixed(2) + ", " + plane[1].toFixed(2) + ", " +
//      plane[2].toFixed(2) + ", " + plane[3].toFixed(2);
//  },
//
//  _vector4ToString: function(v) {
//    return v[0].toFixed(2) + "," + v[1].toFixed(2) + "," +
//      v[2].toFixed(2) + "," +v[3].toFixed(2);
//  },
//
//  _vector3ToString: function(v) {
//    if(v === Infinity || v === -Infinity){
//      return "Infinity";
//    }
//
//    return v[0].toFixed(2) + "," + v[1].toFixed(2) + "," +
//      v[2].toFixed(2);
//  },
//
//  _frustumPlanesToString: function(planes) {
//    return this._planeToString(planes[0]) + "\n" +
//           this._planeToString(planes[1]) + "\n" +
//           this._planeToString(planes[2]) + "\n" +
//           this._planeToString(planes[3]);
//  },
//
//  _fullDebugString: function(){
//    let str = "planes: \n" +
//      this._frustumPlanesToString(this._planes);
//    
//    str += "\n\nhead pos: \n" +
//      this._vector4ToString(this._headPos);
//
//    str += "\n\nraycastRight: \n" +
//      (this._raycastRight).toFixed(5);
//
//    str += "\n\nraycastLeft: \n" +
//      (this._raycastLeft).toFixed(5);
//
//    str += "\n\nraycastTop: \n" +
//      (this._raycastTop).toFixed(5);
//
//    str += "\n\nraycastBottom: \n" +
//      (this._raycastBottom).toFixed(5);
//
//    str += "\n\nDisplay Width X Height: \n" +
//      this.DisplaySize.x.toFixed(5) + " x " +
//      this.DisplaySize.y.toFixed(5);
//
//    return str;
//  }
}

export {DisplaySizeExtractor};