//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2023 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import { CubeDemo } from './CubeDemo';

var AlignToViewportDemo = function(){
  CubeDemo.call(this);

//  this.debugDiv = document.createElement('div');
//  this.debugDiv.id = 'debug';
//  document.body.appendChild(this.debugDiv);

  this.margin = 10;

  let frameGeometry = new THREE.Geometry();
  frameGeometry.vertices.push(new THREE.Vector3( 0.5,  0.5, 0));
  frameGeometry.vertices.push(new THREE.Vector3(-0.5,  0.5, 0));
  frameGeometry.vertices.push(new THREE.Vector3(-0.5, -0.5, 0));
  frameGeometry.vertices.push(new THREE.Vector3( 0.5, -0.5, 0));
  let frameMaterial = new THREE.LineBasicMaterial({color:0x00ff00});
  this.viewportFrame = new THREE.LineLoop(frameGeometry, frameMaterial);
  this.viewportFrame.frustumCulled = false;

  this.viewController.add(this.viewportFrame);

  this.gui.add(this, 'margin', 1, 100).name('Frame Margin');
};

Object.assign(AlignToViewportDemo.prototype, CubeDemo.prototype,{
  zSpaceInit: function(){
    CubeDemo.prototype.zSpaceInit.call(this);
  },

  onBeforeRender: function(){
    CubeDemo.prototype.onBeforeRender.call(this);
    this.updateAlignment();
//    this.updateDebug();
  },

  onFallbackBeforeRender: function(){
    CubeDemo.prototype.onFallbackBeforeRender.call(this);
    this.updateAlignment();
//    this.updateDebug();
  },

  updateAlignment: function(){
    let width = this.viewController.width - 
      this.viewController.metersPerPixelWidth * this.margin * 2;

    let height = this.viewController.height - 
      this.viewController.metersPerPixelHeight * this.margin * 2;

    this.viewportFrame.scale.set(width, height, 1);
  },

//  updateDebug: function(){
//  },
});

export { AlignToViewportDemo };
