//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2023 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import { CubeDemo } from './CubeDemo';

var ExitButtonDemo = function(){
  CubeDemo.call(this);

  // pixel offset from edge of display that button will be placed at
  this.margin = 60;


  this.gui.add(this, 'margin', 1, 100).name('Frame Margin');
};

Object.assign(ExitButtonDemo.prototype, CubeDemo.prototype,{
  zSpaceInit: function(){
    CubeDemo.prototype.zSpaceInit.call(this);

    ///////////////////////////////////////////////////////////////////////////////
    // init button and listen for stylus button down event, then exit WebXR
    ///////////////////////////////////////////////////////////////////////////////
    if(this.exitButton == null){
      this.initExitButton();
      this.stylusPointer.intersectables.push(this.exitButton);
      this.exitButton.addEventListener('dragBegin', (event)=>{
        this.renderer.vr.getSession().end();
      });
    }else{
      this.exitButton.visible = true;
    }

  },

  onZSpaceEnd: function(){
    CubeDemo.prototype.onZSpaceEnd.call(this);
    this.exitButton.visible = false;
  },

  onBeforeRender: function(){
    CubeDemo.prototype.onBeforeRender.call(this);
    this.updateAlignment();
  },

  onFallbackBeforeRender: function(){
    CubeDemo.prototype.onFallbackBeforeRender.call(this);
  },

  initExitButton: function(){
    let exitGeo = new THREE.BoxGeometry(0.01, 0.01, 0.01);
    let material = new THREE.MeshBasicMaterial( {color: 0xff0000} );
    this.exitButton = new THREE.Mesh( exitGeo, material );
    this.exitButton.frustumCulled = false;
    this.viewController.add( this.exitButton );
  },

  updateAlignment: function(){
    ///////////////////////////////////////////////////////////////////////////////
    // position the exit button (not necessary to do every frame like this)
    ///////////////////////////////////////////////////////////////////////////////

    let width = this.viewController.width - 
      this.viewController.metersPerPixelWidth * this.margin * 2;

    let height = this.viewController.height - 
      this.viewController.metersPerPixelHeight * this.margin * 2;

    this.exitButton.position.set(width/2, -height/2, 0);
  },
});

export { ExitButtonDemo };
