//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import { VRButton } from './VRButton';
import { ViewportController } from '../ViewportController';
import { StylusPointer } from '../StylusPointer';

var BaseDemo = function(){
  this.canvas = document.getElementById('main');
  this.clock = new THREE.Clock();
  this.scene = new THREE.Scene();
  this.scene.background = new THREE.Color( 0x808080 );
  this.camera = new THREE.PerspectiveCamera(
    50, window.innerWidth / window.innerHeight, 0.1, 10 );

  this.camera.near = 0.001;
  this.camera.far = 100000;

  this.renderer = new THREE.WebGLRenderer( { canvas: this.canvas, antialias: true } );
  this.renderer.setPixelRatio( window.devicePixelRatio );
  this.renderer.setSize( window.innerWidth, window.innerHeight );
  this.renderer.outputEncoding = THREE.sRGBEncoding;

  this.initViewController();
  this.scene.add(this.viewController);

  if(typeof navigator !== 'undefined' &&
    'xr' in navigator &&
    'supportsSession' in navigator.xr){

    this.renderer.vr.enabled = true;
    this.renderer.vr.setReferenceSpaceType('local');

    if(this.renderer.vr.isPresenting()){
      this.zSpaceInit();
    }else{
      this.renderer.vr.addEventListener('sessionstart',
        ()=>{this.zSpaceInit();});

      this.vrButton = VRButton.createButton( this.renderer );
      document.body.appendChild(this.vrButton);
    }

    this.renderer.vr.addEventListener('sessionend',
      ()=>{
        this.onZSpaceEnd();
      });
  }
  else{
    this.fallbackInit();
  }

  window.addEventListener('resize', ()=>{this.onWindowResize();} );

  this.renderer.setAnimationLoop(()=>{this.onFallbackUpdate();});
};

BaseDemo.prototype = {
  zSpaceInit: function(){
    if(this.stylusPointer == null){
      this.stylusPointer = new StylusPointer(
        this.viewController, this.renderer.vr);
    }
    this.stylusPointer.visible = true;
    this.renderer.setAnimationLoop(()=>{this.onUpdate();});
  },

  fallbackInit: function(){
    this.renderer.setAnimationLoop(()=>{this.onFallbackUpdate();});
  },

  onBeforeRender: function(){
    this.viewController.update();
    this.stylusPointer.update();
  },

  onRender: function(){
    this.renderer.render(this.scene, this.camera);
  },

  onUpdate: function() {
    this.onBeforeRender();
    this.onRender();
  },

  onZSpaceEnd: function(){

    document.body.removeChild(this.canvas);
    this.canvas = document.createElement('canvas');
    this.canvas.id = 'main';
    document.body.appendChild(this.canvas);

    this.stylusPointer.visible = false;

    this.renderer = new THREE.WebGLRenderer( { canvas: this.canvas, antialias: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.vr.enabled = true;
    this.renderer.vr.setReferenceSpaceType('local');

    this.viewController.webXRManager = this.renderer.vr;

    this.renderer.vr.addEventListener('sessionstart',
      ()=>{this.zSpaceInit();});

    this.renderer.vr.addEventListener('sessionend',
      ()=>{this.onZSpaceEnd();});

    document.body.removeChild(this.vrButton);
    this.vrButton = VRButton.createButton( this.renderer );
    document.body.appendChild(this.vrButton);

    this.fallbackInit();
  },

  onFallbackBeforeRender: function(){
    this.viewController.update();
  },

  onFallbackRender: function(){
    this.renderer.render(this.scene, this.camera);
  },

  onFallbackUpdate: function() {
    this.onFallbackBeforeRender();
    this.onFallbackRender();
  },

  onWindowResize: function(){
    if(this.renderer.vr.isPresenting()){ return; }

    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize( window.innerWidth, window.innerHeight );
  

  },

  initViewController: function(){
    this.viewController = new ViewportController(
      this.camera, this.renderer.vr, {width:0.344, height:0.193});
  }
};

export { BaseDemo };