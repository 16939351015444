//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import * as ZSpace from '../webxr-zspace';
import { CubeDemo } from './CubeDemo';

var OrbitControllerDemo = function(){
  CubeDemo.call(this);
};

Object.assign(OrbitControllerDemo.prototype, CubeDemo.prototype, {
  zSpaceInit: function(){
    CubeDemo.prototype.zSpaceInit.call(this);

    this.viewController.assignStylus(this.stylusPointer);

    this.viewController.viewerScale = 15;

    this.scene.add(this.viewController);

    this.setDraggable(this.cube);
  },

  initViewController: function(){
    this.viewController = new ZSpace.ZOrbitController(
      this.camera, this.renderer.vr);
  },

  setupDebugGui: function(){
    CubeDemo.prototype.setupDebugGui.call(this);
  },

  setDraggable: function(mesh){

    mesh.dragPointer = null;
    mesh.dragOffset = new THREE.Matrix4();

    mesh.addEventListener('dragBegin',
      ZSpace.Draggable.prototype.dragBegin.bind(mesh));

    mesh.addEventListener('dragUpdate',
      ZSpace.Draggable.prototype.dragUpdate.bind(mesh));

    mesh.addEventListener('dragEnd',
      ZSpace.Draggable.prototype.dragEnd.bind(mesh));

    this.stylusPointer.intersectables.push(this.cube);
  },
});

export { OrbitControllerDemo };
