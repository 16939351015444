//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import { ViewportController } from './ViewportController';

var ZOrbitController = function(camera, webXRManager){
  ViewportController.call(this, camera, webXRManager);

  this.pivotOffset = new THREE.Object3D();
  this.pivotOffset.attach(this);
  this.pivotOffset.name = 'pivotOffset';

  this.pitchObject = new THREE.Object3D();
  this.pitchObject.name = 'pitchObject';

  this.pitchObject.attach(this.pivotOffset);
  this.attach(this.pitchObject);

  this.stylus = null;

  this._initialDragOffset    = new THREE.Vector3();
  this._initialRigPos        = new THREE.Vector3();
  this._initialRigRot        = new THREE.Quaternion();
  this._initialViewerScale   = 1;
  this._upVector             = new THREE.Vector3(0,1,0);
  this._rightVector          = new THREE.Vector3(1,0,0);
  this._isActuating          = false;
  this.rotateSensitivity     = 3;
  this.zoomSensitivity       = 2;
};

ZOrbitController.prototype =
  Object.assign(Object.create(ViewportController.prototype), {

  constructor: ZOrbitController,

  update: function(){

    if(this.stylus === null){
      ViewportController.prototype.update.call(this);
      return;
    }

    if(!this._isActuating &&
    this.stylus.isDragging &&
    this.stylus.dragObject === null){
      
      this._isActuating = true;
      this._initialDragOffset.copy(this.stylus.position);
      this.getWorldPosition(this._initialRigPos);
      this.getWorldQuaternion(this._initialRigRot);
      this._initialViewerScale = this.viewerScale;

    }else if(this._isActuating && !this.stylus.isDragging){
      this._isActuating = false;

    }else if(this._isActuating && this.stylus.dragObject === null){
      if(this.stylus.dragButton === 0){
        this.panUpdate();
      }else if(this.stylus.dragButton === 3){
        this.rotateUpdate();
      }else if(this.stylus.dragButton === 4){
        this.zoomUpdate();
      }
    }

    ViewportController.prototype.update.call(this);
  },

  panUpdate: function(){
    let ptrPos = new THREE.Vector3().copy(this.stylus.position);
    let offset = new THREE.Vector3().subVectors(this._initialDragOffset, ptrPos);

    offset.multiplyScalar(this.viewerScale);
    offset.applyQuaternion(this._initialRigRot);

    this.position.addVectors(this._initialRigPos, offset);
  },

  rotateUpdate: function(){
    let ptrPos = new THREE.Vector3().copy(this.stylus.position);
    let offset = new THREE.Vector3().subVectors(this._initialDragOffset, ptrPos);

    offset.multiplyScalar(this.rotateSensitivity);

    this.quaternion.copy(this._initialRigRot);
    this.rotateOnWorldAxis(this._upVector, offset.x);
    this.rotateOnWorldAxis(new THREE.Vector3().copy(this._rightVector)
      .applyQuaternion(this.quaternion),
      -offset.y
    );
  },

  zoomUpdate: function(){
    let offset = new THREE.Vector3().subVectors(
      this._initialDragOffset, this.stylus.position);

    this.viewerScale = this._initialViewerScale +
     offset.z * this._initialViewerScale *
     this.zoomSensitivity;
  },

  assignStylus: function(stylus){
    this.stylus = stylus;
    this.add(this.stylus);
  }
});

export { ZOrbitController };