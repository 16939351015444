//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import { CubeDemo } from './CubeDemo';

var NearFarClipDemo = function(){
  CubeDemo.call(this);

  this.scene.remove(this.cube);
  this.cube.geometry.applyMatrix(
    new THREE.Matrix4().makeScale(0.3, 0.3, 0.3));

  let arrayDims = {x_ct: 10, y_ct: 10, width: 5, height: 5};
  this.cubeArr = this.makeArray(this.cube, arrayDims);
  this.scene.add(this.cubeArr);

  this.viewController.depthNear = 0.05;
  this.viewController.depthFar = 1.5;

  this.gui.add(this.viewController,
    'depthNear', 0.01, 1, 0.01).name('Near Clip');

  this.gui.add(this.viewController,
    'depthFar', 0.3, 3, 0.01).name('Far Clip');
}

Object.assign(NearFarClipDemo.prototype, CubeDemo.prototype, {
  makeArray: function(obj, dims){
    let parent = new THREE.Object3D();
    for(let i = 0; i < dims.x_ct; i++){
      for(let j = 0; j < dims.y_ct; j++){
        let cube = obj.clone();
        parent.add(cube);
        cube.position.set(
          dims.width/dims.x_ct/2 + i*(dims.width/dims.x_ct) - dims.width/2,
          0,
          dims.height/dims.y_ct/2 + j*(dims.height/dims.y_ct) - dims.height/2
        );
        ////////////////////////////////////////////////////////////////////////
        // Frustum culling doesn't work correctly   ////////////////////////////
        // with zSpace projection matrices.         ////////////////////////////
        ////////////////////////////////////////////////////////////////////////
        cube.frustumCulled = false;
        ////////////////////////////////////////////////////////////////////////
      }
    }
    return parent;
  },
});

export { NearFarClipDemo };
