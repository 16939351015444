//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';

var StylusPointer = function(vctrl, xrrend){
  THREE.Object3D.call(this);

  this.name = 'StylusPointer';

  //////////////////////////////////////////////////////////////////////////////
  // Private Memebers //////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  this._viewportController = vctrl;
  this._viewportController.add(this);

  this._raycaster      = new THREE.Raycaster();
  this._ray            = this._raycaster.ray;

  // TODO: implement raycast layers rather than _intersectables[]
  // when upgraded to a newer version of threejs that supports it.

  //TODO: implement raycast length limit
  // (ensure it's always at least as long as beam visual length)

  this._intersectables = [];
  this._intersects     = [];
  this._recursive      = false;
  this._hovered        = null;
  this._dragObject     = null;
  this._dragButton     = -1;

  this.buttonStates   = [false, false, false, false, false];

  //TODO: add ability for devs to disable/enable the stylus

  this._timeout     = Date.now();
  this._timeoutMax  = 2000;
  this._oldPosition = new THREE.Vector3();

  this._beamLengthDefault  = 0.5;

  let material  = new THREE.MeshBasicMaterial({color: 0x5cff5c});
  let geometry  = new THREE.CylinderGeometry(0.0007, 0.0007, 1, 6, 1)
    .applyMatrix(new THREE.Matrix4().compose(
      new THREE.Vector3(0,0,-0.5),
      new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI/2, 0, 0)),
      new THREE.Vector3(1,1,1)));

  this._beamVisual = new THREE.Mesh(geometry, material);
  this.add(this._beamVisual);
  this._visible = true;
  this._beamVisual.frustumCulled = false;
};

////////////////////////////////////////////////////////////////////////////////
// Methods /////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

StylusPointer.prototype =
  Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: StylusPointer,

  update: function(){
    this.position.copy(this._controller.position);
    this.quaternion.copy(this._controller.quaternion);
    this._updateVisibilityTimeout();
    this._updateRaycast();
    this._updateButtons();
  },

  _updateVisibilityTimeout: function(){
    this._visible = Date.now() - this._timeout < this._timeoutMax;

    if(this.position.x !== this._oldPosition.x)
      this._timeout = Date.now();

    this._oldPosition.copy(this.position);
  },

  _updateRaycast: function(){
    this._ray.set(
      this.getWorldPosition(new THREE.Vector3(0,0,0)),
      new THREE.Vector3(0,0,-1).applyQuaternion(
        this.getWorldQuaternion(new THREE.Quaternion(0,0,0,1))));

    this.intersects = this._raycaster.intersectObjects(this._intersectables, this._recursive);
    let nearestIntersect = this.intersects[0] ? this.intersects[0] : null;

    if(this._hovered && nearestIntersect){
      if(nearestIntersect.object !== this._hovered.object){
        this.dispatchEvent({
          type:   'hoverEnter',
          pointer: this,
          intersect: nearestIntersect});
      }
    }else if(nearestIntersect){
      this.dispatchEvent({
        type:   'hoverEnter',
        pointer: this,
        intersect: nearestIntersect});
    }

    if(this._hovered){
      if(nearestIntersect){
        if(this._hovered.object !== nearestIntersect.object){
          this.dispatchEvent({
            type: 'hoverExit',
            pointer: this,
            intersect: this._hovered});
        }
      }else{
        this.dispatchEvent({
          type: 'hoverExit',
          pointer: this,
          intersect: this._hovered});
      }
    }

    this._hovered = nearestIntersect;

    if(this._hovered)
    {
      this._beamVisual.scale.setZ(
        this._hovered.distance/this._viewportController.viewerScale);
    }else{
      this._beamVisual.scale.setZ(
        this._beamLengthDefault);
    }
  },

  _updateButtons: function(){
    if(this._session == null){
      return;
    }

    let newButtonStates = this._session
      .inputSources[0].gamepad.buttons;

    for(var i = 0; i < newButtonStates.length; i++){
      if(this.buttonStates[i] !== newButtonStates[i].pressed){

        if(this._dragButton === -1 && newButtonStates[i].pressed){

          this._dragButton = i;

          if(this._hovered && this._dragObject === null){
            this._dragObject = this._hovered.object;
            this._dragObject.dispatchEvent({
              type: 'dragBegin',
              button: i,
              pointer: this
            });
          }

        }
        else if(this.dragButton === i && !newButtonStates[i].pressed){

          this._dragButton = -1;

          if(this._dragObject !== null){
            this._dragObject.dispatchEvent({
              type: 'dragEnd',
              button: i,
              pointer: this
            });
            this._dragObject = null;
          }
        }

        this.dispatchEvent({
          type: 'buttonEvent',
          button: i,
          state: newButtonStates[i].pressed ? 'pressed' : 'released'
        });

      } else if(this._dragButton === i &&
        this.buttonStates[i] == newButtonStates[i].pressed &&
        this._dragObject != null){

        this._dragObject.dispatchEvent({
          type: 'dragUpdate',
          button: i,
          pointer: this
        });
      }
    }

    for(let i = 0; i < newButtonStates.length; i++)
    {
      this.buttonStates[i] = newButtonStates[i].pressed;
    }
  },
});

////////////////////////////////////////////////////////////////////////////////
// Accessors ///////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

StylusPointer.prototype = Object.create(StylusPointer.prototype, {
  _session:{
    get: function(){
      return this._viewportController.session;
    }
  },

  _controller:{
    get: function(){
      return this._viewportController.webXRManager.getController(0);
    }
  },

  dragObject:{
    get: function(){
      return this._dragObject;
    }
  },

  isDragging:{
    get: function(){
      return this._dragButton > -1;
    }
  },

  dragButton:{
    get: function(){
      return this._dragButton;
    }
  },

  intersectables:{
    get: function(){
      return this._intersectables;
    },
    set: function(value){
      if(typeof value === 'array'){
        this._intersectables = value;
      }else{
        console.error('The stylus\' intersectables member must be an array');
      }
    }
  },

  beamLengthDefault:{
    get: function(){
      return this._beamLengthDefault;
    },
    set: function(value){
      if(value <= 0){
        console.error('Stylus beam length must be greater than zero.');
      }else{
        this._beamLengthDefault = value;
      }
    }
  },
});

export { StylusPointer };
