//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import './index.html';
import './main.css';
import { CubeDemo } from './CubeDemo';
import { OrbitControllerDemo } from './OrbitControllerDemo';
import { NearFarClipDemo } from './NearFarClipDemo';
import { AlignToViewportDemo } from './AlignToViewportDemo';
import { StylusDemo } from './StylusDemo';
import { ExitButtonDemo } from './ExitButtonDemo';

// composing a static list of demo information
var demoRegistry = [
  {name: 'Cube', url: 'cube', demo: CubeDemo},
  {name: 'Camera Orbit Control', url:'orbit-controller', demo: OrbitControllerDemo},
  {name: 'Near Far Clipping', url:'near-far-clip', demo: NearFarClipDemo},
  {name: 'Align To Viewport', url:'align-to-viewport', demo: AlignToViewportDemo},
  {name: 'Stylus', url:'stylus', demo: StylusDemo},
  {name: 'Exit Button', url:'exit-button', demo: ExitButtonDemo}
];

var currentDemoUrl = window.location.href.match(/(?<=\?demo=).*/);
currentDemoUrl =
  currentDemoUrl !== null ? currentDemoUrl[0] : demoRegistry[0].url;

var currentDemoOrigin = window.location.href.match(/.*(?=\?demo=)/);
currentDemoOrigin = 
  currentDemoOrigin !== null ? currentDemoOrigin[0] : window.location.href;

var demo = null;
var demoMenuDiv = document.body.querySelector("#demo-menu");

// listing out the demos in the side-bar menu as selectable buttons
for(var i = 0; i < demoRegistry.length; i++){
  var button = document.createElement("div");
  button.classList.add("demo-btn");
  button.innerText = demoRegistry[i].name;
  button.url = currentDemoOrigin + '?demo=' + demoRegistry[i].url;
  button.addEventListener("click", function(){
    // if a demo is selected, reload the page with the corresponding url
    window.location.href = this.url;      
  });
  demoMenuDiv.appendChild(button);

  // cache the demo specified by the url if its listed
  if(demoRegistry[i].url === currentDemoUrl){
    demo = demoRegistry[i].demo;
  }
}

// instantiate the demo specified by the url if found,
// otherwise just pick the first one
if(demo !== null) {
  demo = new demo();
}else{
  demo = new demoRegistry[0].demo();
}