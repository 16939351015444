//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import * as ZSpace from '../webxr-zspace';
import { BaseDemo } from './BaseDemo';
import crateTextureUrl from './assets/textures/crate.gif';
import dat from 'dat.gui/build/dat.gui';

var CubeDemo = function(){
  BaseDemo.call(this);

  //this.pitch = 0;
  //this.yaw = 0;
  this.pitch = 58.34;
  this.yaw   = 342.62;
  this.applyRotation();
  this.viewController.position.set(-0.15, 1.28, 0.82);
  this.viewController.viewerScale = 9.85;

  this.setupCubeScene();

  this.setupDebugGui();
};
  
Object.assign(CubeDemo.prototype, BaseDemo.prototype, {
  zSpaceInit: function(){
    BaseDemo.prototype.zSpaceInit.call(this);
  },

  onBeforeRender: function(){
    //this.spinCube();
    BaseDemo.prototype.onBeforeRender.call(this);
  },

  onFallbackBeforeRender: function(){
    //this.spinCube();
    BaseDemo.prototype.onFallbackBeforeRender.call(this);
  },

  //spinCube: function(){
  //  let delta = this.clock.getDelta();
  //  this.cube.rotation.x += 0.14*delta;
  //  this.cube.rotation.y += 0.28*delta;
  //},

  applyRotation: function(){
    let yawRotation = new THREE.Quaternion().setFromEuler(
      new THREE.Euler(0, this.yaw/360*(Math.PI*2), 0));

    let pitchRotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(
        this.pitch/360*(Math.PI*2)-Math.PI/2, 0, 0));

    this.viewController.quaternion.multiplyQuaternions(yawRotation, pitchRotation);
  },

  setupCubeScene: function(){
    this.hemiLight = new THREE.HemisphereLight(
      new THREE.Color(131/255, 190/255, 247/255),
      new THREE.Color(87/255, 41/255, 21/255), 2);
    this.hemiLight.position.set(0, 10, 0);
    this.scene.add(this.hemiLight);

    this.pointLight = new THREE.PointLight(0xffffff, 1, 100);
    this.pointLight.position.set(-50,100,-25);
    this.scene.add(this.pointLight);

    var cubeGeo = new THREE.BoxGeometry(1,1,1);
    var crateTexture = new THREE.TextureLoader().load(crateTextureUrl);
    var material = new THREE.MeshPhongMaterial({map:crateTexture});
    this.cube = new THREE.Mesh(cubeGeo, material);
    this.cube.frustumCulled = false;
    this.cube.position.set(0, 0.5, 0);
    this.scene.add(this.cube);

    this.grid = new THREE.GridHelper(4, 10, 'blue', 'green');
    this.grid.frustumCulled = false;
    this.scene.add(this.grid);
  },

  setupDebugGui: function(){
    this.gui = new dat.GUI();
    this.gui.add(this.viewController.position, 'x', -3.00, 3.00, 0.01).name('Pos X:');
    this.gui.add(this.viewController.position, 'y', -3.00, 3.00, 0.01).name('Pos Y:');
    this.gui.add(this.viewController.position, 'z', -3.00, 3.00, 0.01).name('Pos Z:');

    this.gui.add(this, 'pitch', 0.00, 90.00, 0.01)
      .name('Pitch')
      .onChange((value)=>{
        this.applyRotation();
      });

    this.gui.add(this, 'yaw', 0.00, 360.00, 0.01)
      .name('Yaw')
      .onChange((value)=>{
        this.applyRotation();
      });

    this.gui.add(this.viewController, 'viewerScale', 5, 20, 0.01).name('Viewer Scale');
  }
});

export { CubeDemo };
