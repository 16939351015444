//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';
import * as ZSpace from '../webxr-zspace';
import { CubeDemo } from './CubeDemo';

// #####################################################################
// This demo shows three options (among others) for initializing
// a draggable mesh.
// 
// Option 1: Instantiate a new Draggable.
// 
// Option 2: Copy an existing mesh to a new Draggable.
// 
// Option 3: Manually assign the necessary members and event listeners
//           to an existing mesh.
// 
// #####################################################################

var StylusDemo = function(){
  CubeDemo.call(this);

  // ### Option 1 ###
  this.draggableCube = new ZSpace.Draggable(
    new THREE.BoxGeometry(0.5, 0.5, 0.5),
    new THREE.MeshPhongMaterial({color: 'green'}));
  this.scene.add(this.draggableCube);
  this.draggableCube.position.x = 1.5;
  this.draggableCube.name = 'green cube';
  this.draggableCube.frustumCulled = false;

  // ### Option 2 ###
  let copyable = new THREE.Mesh(
    new THREE.BoxGeometry(0.3, 0.3, 0.3),
    new THREE.MeshPhongMaterial({color: 'red'}));
  copyable.position.x = -1.5;

  this.draggableCopy = new ZSpace.Draggable(
    copyable.geometry, copyable.material).copy(copyable);
  this.scene.add(this.draggableCopy);
  this.draggableCopy.name = 'red cube';
  this.draggableCopy.frustumCulled = false;

  // ### Option 3 ###
  this.setDraggable(this.cube);

  this.cube.name = 'wooden cube';
  this.cube.frustumCulled = false;

  this.debugDiv = document.createElement('div');
  this.debugDiv.id = 'debug';
  document.body.appendChild(this.debugDiv);
  this.debugDiv.innerText = '';
  this.debugEntries = [];
};

Object.assign(StylusDemo.prototype, CubeDemo.prototype, {
  zSpaceInit: function(){
    CubeDemo.prototype.zSpaceInit.call(this);

    this.stylusPointer.intersectables.push(
      this.draggableCube,
      this.draggableCopy,
      this.cube);

    this.stylusPointer.addEventListener('hoverEnter', (event)=>{
      console.log(`Stylus Hover Entered: ${event.intersect.object.name}`);
      this.appendDebug(`Stylus Hover Entered: ${event.intersect.object.name}`);
    })
    this.stylusPointer.addEventListener('hoverExit', (event)=>{
      console.log(`Stylus Hover Exited: ${event.intersect.object.name}`);
      this.appendDebug(`Stylus Hover Exited: ${event.intersect.object.name}`);
    })
  },

  setDraggable(mesh){

    mesh.dragPointer = null;
    mesh.dragOffset = new THREE.Matrix4();

    mesh.addEventListener('dragBegin',
      ZSpace.Draggable.prototype.dragBegin.bind(mesh));

    mesh.addEventListener('dragUpdate',
      ZSpace.Draggable.prototype.dragUpdate.bind(mesh));

    mesh.addEventListener('dragEnd',
      ZSpace.Draggable.prototype.dragEnd.bind(mesh));
  },

  appendDebug: function(entry){
    this.debugEntries.push(entry);

    if(this.debugEntries.length > 5){
      this.debugEntries.shift();
    }

    this.debugDiv.innerText = '';
    for(let i = 0; i < this.debugEntries.length; i++){
      this.debugDiv.innerText += this.debugEntries[i] + '\n';
    }
  },
});

export { StylusDemo };
