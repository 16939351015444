//////////////////////////////////////////////////////////////////////////
//
//  Copyright (C) 2007-2019 zSpace, Inc.  All Rights Reserved.
//
//////////////////////////////////////////////////////////////////////////

import * as THREE from 'THREE';

var Draggable = function(geometry, material){
  THREE.Mesh.call(this, geometry, material);

  this.dragPointer = null;
  this.dragOffset = new THREE.Matrix4();

  this.addEventListener('dragBegin', this.dragBegin);
  this.addEventListener('dragUpdate', this.dragUpdate);
  this.addEventListener('dragEnd', this.dragEnd);
}

Draggable.prototype =
  Object.assign(Object.create(THREE.Mesh.prototype),{
  constructor: Draggable,

  dragBegin: function(event){
    if(this.dragPointer === null && event.button === 0){
      this.dragPointer = event.pointer;
      this.dragOffset.copy(this.matrixWorld);
      this.dragOffset.premultiply(new THREE.Matrix4().getInverse(
        this.dragPointer.matrixWorld));
    }
  },

  dragUpdate: function(){
    if(this.dragPointer){
      this.matrixWorld.copy(this.dragOffset);
      this.matrixWorld.premultiply(this.dragPointer.matrixWorld);
      this.matrix.copy(this.matrixWorld);

      if(this.parent){
        this.matrix.premultiply(new THREE.Matrix4().getInverse(
          this.parent.matrixWorld));
      }

      this.matrix.decompose(
        this.position,
        this.quaternion,
        this.scale);
    }
  },

  dragEnd: function(event){
    if(this.dragPointer === event.pointer){
      this.dragPointer = null;
    }
  }
});

export { Draggable };